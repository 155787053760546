import { PaymentMethod, PaymentMethodEnum } from "@bees-grow-shared/services";

import { GetPaymentMethodsResponse } from "@/services/paymentMethods/getPaymentMethods/model/GetPaymentMethodsResponse";

export function getPaymentMethodsConverter(
  response: GetPaymentMethodsResponse[],
): PaymentMethod[] {
  return response.map((paymentMethod) => ({
    method: paymentMethod.paymentMethod as PaymentMethodEnum,
    options: paymentMethod.options.map((option) => ({
      term: option.paymentTerm,
      description: option.description,
      code: option.paymentMethodCode,
    })),
  }));
}
