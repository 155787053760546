import { getCountryFormats, PaymentMethod } from "@bees-grow-shared/services";

import { getPaymentMethods } from "@/services/paymentMethods/getPaymentMethods/GetPaymentMethodsService";

import { getPaymentMethodsConverter } from "./converters/GetPaymentMethodsConverter";

interface GetPaymentMethodsUseCaseProps {
  accountId: string;
  vendorId: string;
  country: string;
}

export const GetPaymentMethodsUseCase = async ({
  accountId,
  vendorId,
  country,
}: GetPaymentMethodsUseCaseProps): Promise<PaymentMethod[]> => {
  const { locale } = getCountryFormats(country);

  const response = await getPaymentMethods({
    headers: {
      vendorId,
      "Accept-Language": locale,
    },
    pathParams: { accountId },
  });

  return getPaymentMethodsConverter(response);
};
