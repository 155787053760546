import { tokens } from "@bees-grow-shared/theme";
import { Radio } from "@hexa-ui/components";
import styled, { css } from "styled-components";

export const PaymentMethodSection = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

interface RadioSelectionItemProps {
  hasMethodMissing: boolean;
  hasTermMissing: boolean;
}

export const RadioSelectionItem = styled(Radio.Item).attrs(() => ({
  css: {
    margin: "0.5rem 0",
  },
}))<RadioSelectionItemProps>`
  ${({ hasMethodMissing, hasTermMissing }) => css`
    ${hasMethodMissing &&
    `
      border-color: ${tokens.color.foundation.bg.error.default};

      span::after {
        background-color: ${tokens.color.foundation.bg.error.default};
      }
    `}

    ${hasTermMissing &&
    `
      &[aria-checked="true"] {
        border-color: ${tokens.color.foundation.bg.error.default};

        span::after {
          background-color: ${tokens.color.foundation.bg.error.default};
        }
      }
    `}
  `}
`;
