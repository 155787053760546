import { axiosInstance } from "@bees-grow-shared/services";

import { GROW_PAYMENT_METHOD_SERVICE_ENDPOINTS } from "../endpoints";
import {
  GetPaymentMethodsHeadersRequest,
  GetPaymentMethodsPathParamsRequest,
} from "./model/GetPaymentMethodsRequest";
import { GetPaymentMethodsResponse } from "./model/GetPaymentMethodsResponse";

interface GetPaymentMethodsProps {
  pathParams: GetPaymentMethodsPathParamsRequest;
  headers: GetPaymentMethodsHeadersRequest;
}

export async function getPaymentMethods({
  pathParams,
  headers,
}: GetPaymentMethodsProps): Promise<GetPaymentMethodsResponse[]> {
  const { accountId } = pathParams;

  const { data } = await axiosInstance.get<GetPaymentMethodsResponse[]>(
    GROW_PAYMENT_METHOD_SERVICE_ENDPOINTS.GET_PAYMENT_METHODS(accountId),
    { headers: { ...headers } },
  );

  return data;
}
