import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  Checkout: {
    TITLE: "Checkout",
  },
  PaymentMethod: {
    TITLE: "Payment Method",
    TERM_PLACEHOLDER: "Due Date",

    ErrorBoundary: {
      MESSAGE: "Unable to load payment methods.",
      RELOAD_BUTTON_LABEL: "Try again",
      METHOD_NOT_SELECTED_TEXT: "Select a payment method",
      TERM_NOT_SELECTED_TEXT: "Select a due date",
    },

    Methods: {
      CREDIT: "Credit",
      CASH: "Cash",
      BANK_SLIP: "Bank slip",
      BANK_SLIP_INSTALLMENT: "Bank slip installment",
      CHECK: "Check",
    },
  },
  OrderDetails: {
    TITLE: "Details",
    PLACEHOLDER: "Enter any details of the order...",
  },
};

export default enUS;
