import { tokens } from "@bees-grow-shared/theme";
import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface SectionHeaderProps {
  hasValidationError?: boolean;
}

export const SectionHeader = styled(Heading)<SectionHeaderProps>`
  color: ${({ hasValidationError }) =>
    hasValidationError
      ? tokens.color.foundation.txt.error
      : tokens.color.foundation.txt.default};
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const Content = styled.div``;

export const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SkeletonItem = styled.div`
  display: flex;
  gap: 0.5rem;
`;
