import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  cartSharedService,
  paymentMethodSharedService,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

import { GetPaymentMethodsUseCase } from "@/useCases/paymentMethod/getPaymentMethods/GetPaymentMethodsUseCase";

interface UsePaymentMethodProps {
  isPaymentMethodLoading: boolean;
  hasPaymentMethodError: boolean;
  hasValidationError: boolean;
  getPaymentMethods: () => void;
}

export function usePaymentMethod(): UsePaymentMethodProps {
  const cartService = cartSharedService();
  const accountService = accountSharedService();
  const paymentMethodService = paymentMethodSharedService();
  const userConfigService = userConfigSharedService();

  const { accountId, vendorId } = useSharedUnit(accountService);
  const {
    done: paymentMethodDone,
    isLoading: isPaymentMethodLoading,
    error: hasPaymentMethodError,
  } = useSharedUnit(paymentMethodService);
  const {
    payment: { isMissingMethod, isMissingTerm, showValidationInfo },
  } = useSharedUnit(cartService);
  const { country } = useSharedUnit(userConfigService);

  const shouldGetPaymentMethods = !paymentMethodDone && !isPaymentMethodLoading;

  const hasValidationError = useMemo(() => {
    return showValidationInfo && (isMissingMethod || isMissingTerm);
  }, [isMissingMethod, isMissingTerm, showValidationInfo]);

  const getPaymentMethods = useCallback(() => {
    paymentMethodService.getPaymentMethods(
      GetPaymentMethodsUseCase({
        accountId,
        vendorId,
        country,
      }),
    );
  }, [accountId, country, paymentMethodService, vendorId]);

  useEffect(() => {
    if (shouldGetPaymentMethods) {
      getPaymentMethods();
    }
  }, [getPaymentMethods, shouldGetPaymentMethods]);

  return {
    isPaymentMethodLoading,
    hasPaymentMethodError,
    hasValidationError,
    getPaymentMethods,
  };
}
