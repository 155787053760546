import { cartSharedService } from "@bees-grow-shared/services";

export const useOrderDetails = () => {
  const cartService = cartSharedService();

  const handleOrderDetails = (details: string) => {
    cartService.setCartState({
      details: details,
    });
  };

  return {
    handleOrderDetails,
  };
};
