import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Divider, Heading, IconButton } from "@hexa-ui/components";
import { ArrowLeft } from "@hexa-ui/icons";

import DeliveryDate from "@/components/deliveryDate/DeliveryDate";
import OrderDetails from "@/components/orderDetails/OrderDetails";
import OrderProducts from "@/components/orderProducts/OrderProducts";
import OrderSummary from "@/components/orderSummary/OrderSummary";
import PaymentMethod from "@/components/paymentMethod/PaymentMethod";
import PurchaseOrder from "@/components/purchaseOrder/PurchaseOrder";

import * as Styled from "./CheckoutPage.styles";

export default function CheckoutPage() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const pageTitle = formatMessage({ id: "Checkout.TITLE" });

  const handleClickGoBack = () => {
    navigate(-1);
  };

  return (
    <Styled.CheckoutContainer data-testid="checkout-page-container">
      <Styled.CheckoutHeader>
        <IconButton
          variant="tertiary"
          icon={ArrowLeft}
          onClick={handleClickGoBack}
          data-testid="checkout-page-go-back-button"
        />

        <Heading size="H3" data-testid="checkout-page-header">
          {pageTitle}
        </Heading>
      </Styled.CheckoutHeader>
      <Styled.CenteredCardContainer
        elevated="small"
        border="medium"
        data-testid="checkout-page-centered-card-container"
      >
        <Styled.CheckoutLayoutSection data-testid="checkout-page-layout-section">
          <Styled.OrderDetailsSection data-testid="checkout-page-order-details-section">
            <PaymentMethod />
            <PurchaseOrder />
            <DeliveryDate />
            <OrderDetails />
          </Styled.OrderDetailsSection>
          <Divider
            orientation="vertical"
            decorative
            data-testid="checkout-page-divider"
          />
          <Styled.OrderSummarySection data-testid="checkout-page-order-summary-section">
            <OrderProducts />
            <OrderSummary />
          </Styled.OrderSummarySection>
        </Styled.CheckoutLayoutSection>
      </Styled.CenteredCardContainer>
    </Styled.CheckoutContainer>
  );
}
