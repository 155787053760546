import React from "react";

import { Radio } from "@hexa-ui/components";

import PaymentTermSelection from "../paymentTermSelection/PaymentTermSelection";
import { usePaymentMethodRadioSelection } from "./hooks/usePaymentMethodRadioSelection";
import * as Styled from "./PaymentMethodRadioSelection.styles";

export default function PaymentMethodRadioSelection() {
  const {
    validationError,
    validationErrorMessage,
    hasMethodMissing,
    hasTermMissing,
    controlledPaymentMethods,
    onPaymentMethodChange,
    onPaymentTermChange,
  } = usePaymentMethodRadioSelection();

  return (
    <Radio.Root
      onValueChange={onPaymentMethodChange}
      error={validationError}
      errorMessage={validationErrorMessage}
      data-testid="payment-method-radio-selection"
    >
      {controlledPaymentMethods.map((paymentMethod) => (
        <Styled.PaymentMethodSection key={paymentMethod.method}>
          <Styled.RadioSelectionItem
            key={paymentMethod.method}
            id={paymentMethod.method}
            value={paymentMethod.method}
            label={paymentMethod.methodLabel}
            hasMethodMissing={hasMethodMissing}
            hasTermMissing={hasTermMissing}
            data-testid="payment-method-radio-item"
          />

          {paymentMethod.hasTermSelection && (
            <PaymentTermSelection
              controlledPaymentMethod={paymentMethod}
              onChange={onPaymentTermChange}
              data-testid="payment-term-selection"
            />
          )}
        </Styled.PaymentMethodSection>
      ))}
    </Radio.Root>
  );
}
