import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
  Checkout: {
    TITLE: "Checkout",
  },
  PaymentMethod: {
    TITLE: "Método de pagamento",
    TERM_PLACEHOLDER: "Data de vencimento",

    ErrorBoundary: {
      MESSAGE: "Não foi possível carregar os métodos de pagamento.",
      RELOAD_BUTTON_LABEL: "Tentar novamente",
      METHOD_NOT_SELECTED_TEXT: "Selecione o método de pagamento",
      TERM_NOT_SELECTED_TEXT: "Selecione uma data de vencimento",
    },

    Methods: {
      CREDIT: "Crédito",
      CASH: "Dinheiro",
      BANK_SLIP: "Boleto",
      BANK_SLIP_INSTALLMENT: "Boleto Parcelado",
      CHECK: "Cheque",
    },
    OrderDetails: {
      TITLE: "Detalhes",
      PLACEHOLDER: "Insira detalhes do pedido...",
    },
  },
};

export default ptBR;
