import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  CartPayment,
  cartSharedService,
  PaymentMethod,
  PaymentMethodEnum,
  paymentMethodSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";

export interface ControlledPaymentMethod extends PaymentMethod {
  methodLabel: string;
  hasTermSelection: boolean;
  termSelectionEnabled: boolean;
}

interface SelectedTerm {
  term: number;
  code: string;
}

interface UsePaymentMethodRadioSelectionProps {
  controlledPaymentMethods: ControlledPaymentMethod[];
  validationError: boolean;
  validationErrorMessage: string;
  hasMethodMissing: boolean;
  hasTermMissing: boolean;
  onPaymentMethodChange: (paymentMethod: PaymentMethodEnum) => void;
  onPaymentTermChange: (
    paymentMethod: PaymentMethodEnum,
    paymentTerm: number,
  ) => void;
}

export function usePaymentMethodRadioSelection(): UsePaymentMethodRadioSelectionProps {
  const { formatMessage } = useIntl();

  const [selectedTerms, setSelectedTerms] =
    useState<Record<PaymentMethodEnum, SelectedTerm>>();

  const cartService = cartSharedService();
  const paymentMethodService = paymentMethodSharedService();

  const cartStates = useSharedUnit(cartService);
  const paymentMethodStates = useSharedUnit(paymentMethodService);

  const validationError = useMemo(() => {
    const {
      payment: { showValidationInfo, isMissingMethod, isMissingTerm },
    } = cartStates;

    return showValidationInfo && (isMissingMethod || isMissingTerm);
  }, [cartStates]);

  const validationErrorMessage = useMemo(() => {
    const {
      payment: { isMissingMethod, isMissingTerm },
    } = cartStates;

    if (isMissingMethod) {
      return formatMessage({
        id: "PaymentMethod.ErrorBoundary.METHOD_NOT_SELECTED_TEXT",
      });
    }

    if (isMissingTerm) {
      return formatMessage({
        id: "PaymentMethod.ErrorBoundary.TERM_NOT_SELECTED_TEXT",
      });
    }

    return "";
  }, [cartStates, formatMessage]);

  const hasMethodMissing = useMemo(() => {
    const {
      payment: { showValidationInfo, isMissingMethod },
    } = cartStates;

    return isMissingMethod && showValidationInfo;
  }, [cartStates]);

  const hasTermMissing = useMemo(() => {
    const {
      payment: { showValidationInfo, isMissingTerm },
    } = cartStates;

    return isMissingTerm && showValidationInfo;
  }, [cartStates]);

  const controlledPaymentMethods: ControlledPaymentMethod[] = useMemo(() => {
    const {
      payment: { method: selectedPaymentMethod },
    } = cartStates;
    const { paymentMethods } = paymentMethodStates;

    return paymentMethods.map((paymentMethod) => {
      return {
        ...paymentMethod,
        methodLabel: formatMessage({
          id: `PaymentMethod.Methods.${paymentMethod.method}`,
        }),
        hasTermSelection: paymentMethod.options.length > 1,
        termSelectionEnabled: selectedPaymentMethod === paymentMethod.method,
      };
    });
  }, [cartStates, formatMessage, paymentMethodStates]);

  const handleChangePaymentCart = useCallback(
    (updatedPaymentMethod: Partial<CartPayment>) => {
      const { payment } = cartStates;

      cartService.setCartState({
        payment: {
          ...payment,
          ...updatedPaymentMethod,
        },
      });
    },
    [cartService, cartStates],
  );

  const onPaymentMethodChange = useCallback(
    (paymentMethod: PaymentMethodEnum) => {
      const methodDetails = controlledPaymentMethods.find(
        (method) => method.method === paymentMethod,
      );

      const { hasTermSelection, options } = methodDetails;

      const code = hasTermSelection
        ? selectedTerms?.[paymentMethod]?.code
        : options[0].code;

      const term = hasTermSelection
        ? selectedTerms?.[paymentMethod]?.term
        : null;

      const isMissingMethod = !paymentMethod;
      const isMissingTerm = hasTermSelection && !term;

      handleChangePaymentCart({
        method: paymentMethod,
        code,
        term,
        isMissingMethod,
        isMissingTerm,
        showValidationInfo: false,
      });
    },
    [controlledPaymentMethods, handleChangePaymentCart, selectedTerms],
  );

  const onPaymentTermChange = useCallback(
    (paymentMethod: PaymentMethodEnum, paymentTerm: number) => {
      const paymentCode = controlledPaymentMethods
        .find((method) => method.method === paymentMethod)
        ?.options.find((option) => option.term === paymentTerm)?.code;

      const isMissingTerm = !paymentTerm;

      setSelectedTerms((prev) => ({
        ...prev,
        [paymentMethod]: {
          term: paymentTerm,
          code: paymentCode,
        },
      }));

      handleChangePaymentCart({
        term: paymentTerm,
        code: paymentCode,
        isMissingTerm,
        showValidationInfo: false,
      });
    },
    [controlledPaymentMethods, handleChangePaymentCart],
  );

  return {
    validationError,
    validationErrorMessage,
    hasMethodMissing,
    hasTermMissing,
    controlledPaymentMethods,
    onPaymentMethodChange,
    onPaymentTermChange,
  };
}
