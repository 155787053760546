import MessageMap from "../i18n.d";

const es419: MessageMap = {
  Checkout: {
    TITLE: "Checkout",
  },
  PaymentMethod: {
    TITLE: "Método de pago",
    TERM_PLACEHOLDER: "Fecha de vencimiento",

    ErrorBoundary: {
      MESSAGE: "No se pueden cargar los métodos de pago.",
      RELOAD_BUTTON_LABEL: "Intentar otra vez",
      METHOD_NOT_SELECTED_TEXT: "Selecciona el método de pago",
      TERM_NOT_SELECTED_TEXT: "Seleccione una fecha de vencimiento",
    },

    Methods: {
      CREDIT: "Línea de compra",
      CASH: "Efectivo",
      BANK_SLIP: "Plazos de pago",
      BANK_SLIP_INSTALLMENT: "Pago aplazado",
      CHECK: "Cheque",
    },
    OrderDetails: {
      TITLE: "Detalles",
      PLACEHOLDER: "Ingrese detalles del pedido...",
    },
  },
};

export default es419;
