import { useIntl } from "react-intl";

import { RetryAlert } from "@bees-grow-shared/components";
import { cartSharedService, useSharedUnit } from "@bees-grow-shared/services";
import { SkeletonLoader } from "@hexa-ui/components";

import { ErrorBoundaryProps } from "@/types/ErrorBoundary";

import PaymentMethodRadioSelection from "./components/paymentMethodRadioSelection/PaymentMethodRadioSelection";
import { usePaymentMethod } from "./hooks/usePaymentMethod";
import * as Styled from "./PaymentMethod.styles";

export default function PaymentMethod() {
  const { formatMessage } = useIntl();
  const {
    hasPaymentMethodError,
    isPaymentMethodLoading,
    getPaymentMethods,
    hasValidationError,
  } = usePaymentMethod();

  /* ------ THIS CODE WILL BE REMOVED ------ */
  const cartService = cartSharedService();
  const { payment } = useSharedUnit(cartService);
  /* ------ THIS CODE WILL BE REMOVED ------ */

  if (isPaymentMethodLoading) {
    return <Loading />;
  }

  if (hasPaymentMethodError) {
    return <ErrorBoundary retryAction={getPaymentMethods} />;
  }

  return (
    <Styled.Section data-testid="payment-method-section">
      <Styled.SectionHeader
        data-testid="payment-method-section-header"
        hasValidationError={hasValidationError}
      >
        {formatMessage({ id: "PaymentMethod.TITLE" })}
      </Styled.SectionHeader>

      <Styled.Content data-testid="payment-method-section-content">
        <PaymentMethodRadioSelection />
      </Styled.Content>

      {/* ------ THIS CODE WILL BE REMOVED ------ */}
      <button
        data-testid="payment-method-validate-button"
        onClick={() =>
          cartService.setCartState({
            payment: {
              ...payment,
              showValidationInfo: true,
            },
          })
        }
      >
        Validate method
      </button>
      {/* ------ THIS CODE WILL BE REMOVED ------ */}
    </Styled.Section>
  );
}

function Loading() {
  return (
    <Styled.Section data-testid="payment-method-section-loading">
      <SkeletonLoader
        height="20px"
        width="118px"
        data-testid="payment-method-section-header-loading"
      />

      <Styled.Content data-testid="payment-method-section-content-loading">
        <Styled.SkeletonContent>
          {Array.from({ length: 4 }).map((_, index) => (
            <Styled.SkeletonItem
              key={index}
              data-testid={`payment-method-skeleton-item-${index}`}
            >
              <SkeletonLoader height="24px" width="24px" />
              <SkeletonLoader height="24px" width="120px" />
            </Styled.SkeletonItem>
          ))}
        </Styled.SkeletonContent>
      </Styled.Content>
    </Styled.Section>
  );
}

function ErrorBoundary({ retryAction }: Readonly<ErrorBoundaryProps>) {
  const { formatMessage } = useIntl();

  return (
    <Styled.Section data-testid="payment-method-section">
      <Styled.SectionHeader data-testid="payment-method-section-header">
        {formatMessage({ id: "PaymentMethod.TITLE" })}
      </Styled.SectionHeader>

      <RetryAlert
        message={formatMessage({ id: "PaymentMethod.ErrorBoundary.MESSAGE" })}
        actionLabel={formatMessage({
          id: "PaymentMethod.ErrorBoundary.RELOAD_BUTTON_LABEL",
        })}
        action={retryAction}
        data-testid="payment-method-retry-alert"
      />
    </Styled.Section>
  );
}
