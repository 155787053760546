import React, { createContext, useContext } from "react";

import { EnvConfig } from "./EnvProvider.types";

export const EnvContext = createContext<EnvConfig>({});

export const useEnvContext = (): EnvConfig => useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): JSX.Element => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
