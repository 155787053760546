import { useIntl } from "react-intl";

import { useOrderDetails } from "./hooks/useOrderDetails";
import * as Styled from "./OrderDetails.styles";

export default function OrderDetails() {
  const { handleOrderDetails } = useOrderDetails();
  const { formatMessage } = useIntl();

  return (
    <Styled.Section data-testid="order-details-section">
      <Styled.TextAreaSection
        id="text-area-with-label"
        data-testid="text-area-with-label"
        placeholder={formatMessage({
          id: "OrderDetails.PLACEHOLDER",
        })}
        label={formatMessage({
          id: "OrderDetails.TITLE",
        })}
        onBlur={(event) => handleOrderDetails(event.target.value)}
      />
    </Styled.Section>
  );
}
