import * as Styled from "./DeliveryDate.styles";

export default function DeliveryDate() {
  return (
    <Styled.Section>
      <Styled.SectionHeader data-testid="delivery-date-section-header">
        Delivery Date
      </Styled.SectionHeader>

      <Styled.Content data-testid="delivery-date-section-content" />
    </Styled.Section>
  );
}
