import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "@/services/MicroServices";

enum ENDPOINTS {
  PAYMENT_METHODS = "/v1/accounts/{accountId}/payment-methods",
}

export const GROW_PAYMENT_METHOD_SERVICE_ENDPOINTS = {
  GET_PAYMENT_METHODS: (accountId: string) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_ORDER_TAKING_BFF,
      endpoint: ENDPOINTS.PAYMENT_METHODS,
      pathParams: { accountId },
    }),
};
