import { tokens } from "@bees-grow-shared/theme";
import { TextArea } from "@hexa-ui/components";
import styled from "styled-components";

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TextAreaSection = styled(TextArea)`
  width: 356px;
  height: 122px;
  border: 1px solid ${tokens.color.foundation.border.secondary};

  &::placeholder {
    color: ${tokens.color.foundation.txt.secondary};
  }
`;
