import * as Styled from "./OrderSummary.styles";

export default function OrderSummary() {
  return (
    <Styled.Section>
      <Styled.SectionHeader data-testid="order-summary-section-header">
        Order Summary
      </Styled.SectionHeader>

      <Styled.Content data-testid="order-summary-section-content" />
    </Styled.Section>
  );
}
