import { tokens } from "@bees-grow-shared/theme";
import { Heading } from "@hexa-ui/components";
import styled from "styled-components";

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SectionHeader = styled(Heading)`
  font-family: ${tokens.fonts["font-family"]["Work Sans"]};
  font-weight: ${tokens.fonts["font-weight"].semibold};
  font-size: ${tokens.measure["font-size"]["fontsize-2"]};
  line-height: ${tokens.measure["line-height"]["lineheight-5"]};
`;

export const Content = styled.div`
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  height: 3rem;
`;
