import { useIntl } from "react-intl";

import {
  PaymentMethodEnum,
  PaymentMethodOption,
} from "@bees-grow-shared/services";
import { Select } from "@hexa-ui/components";

import { ControlledPaymentMethod } from "../paymentMethodRadioSelection/hooks/usePaymentMethodRadioSelection";
import * as Styled from "./PaymentTermSelection.styles";

interface PaymentTermSelectionProps {
  controlledPaymentMethod: ControlledPaymentMethod;
  onChange: (paymentMethod: PaymentMethodEnum, paymentTerm: number) => void;
}

export default function PaymentTermSelection({
  controlledPaymentMethod,
  onChange,
}: PaymentTermSelectionProps) {
  const { formatMessage } = useIntl();

  const { options, termSelectionEnabled, method } = controlledPaymentMethod;

  const shouldDisableTermSelection = !termSelectionEnabled;
  const selectPlaceholder = formatMessage({
    id: "PaymentMethod.TERM_PLACEHOLDER",
  });

  const handlePaymentTermChange = (paymentTerm: string) => {
    onChange(method, Number(paymentTerm));
  };

  return (
    <Styled.Container>
      <Select.Root
        onChange={handlePaymentTermChange}
        data-testid="payment-term-select"
        shape="pill"
        placeholder={selectPlaceholder}
        disabled={shouldDisableTermSelection}
        size="small"
      >
        {options.map((option: PaymentMethodOption) => (
          <Select.Option
            key={option.term}
            data-testid={`payment-term-select-option-${option.term}`}
            value={String(option.term)}
          >
            {option.description}
          </Select.Option>
        ))}
      </Select.Root>
    </Styled.Container>
  );
}
