import * as Styled from "./PurchaseOrder.styles";

export default function PurchaseOrder() {
  return (
    <Styled.Section>
      <Styled.SectionHeader data-testid="purchase-order-section-header">
        Purchase Order
      </Styled.SectionHeader>

      <Styled.Content data-testid="purchase-order-section-content" />
    </Styled.Section>
  );
}
