import { Card } from "@hexa-ui/components";
import styled from "styled-components";

export const CheckoutContainer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CheckoutHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const CenteredCardContainer = styled(Card)`
  width: 100%;
  min-width: fit-content;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
`;

export const CheckoutLayoutSection = styled.div`
  display: grid;
  grid-template-columns: 22.25rem auto 30rem;
  gap: 1rem;
`;

export const CheckoutSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const OrderDetailsSection = styled(CheckoutSection)``;

export const OrderSummarySection = styled(CheckoutSection)``;
